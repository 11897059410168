export enum MsgType {
  msg_type_none,
  msg_type_text,
  msg_type_img,
  msg_type_voice,
  msg_type_card,
  msg_type_video,
  msg_type_emoji,
  msg_type_location,
  msg_type_link,
  msg_type_hb, // 红包
  msg_type_tp, // 转账
  msg_type_share_location, // 共享位置
  msg_type_voip_voice, // 语音通话
  msg_type_voip_video, // 视频通话
  msg_type_card_item, // 卡券
  msg_type_file, // 文件
  msg_type_weapp, // 小程序
  msg_type_msg_forward, // 聊天记录转发
  msg_type_gif = 21,
}

export enum BroadcastMsgContentType {
  msg_type = 1,      // 消息
  clear_red_dot,     // 消红点
  robot_status,      // 机器人状态
  new_friend_verify, // 新好友验证
  verify_pass,       // 通过好友
}

export interface WeXMessage {
  id: number;
  wechat_id: number;
  friend_or_chatroom_wechat_id: string;
  from_username: string;
  to_username: string;
  from_username_nick_name: string;
  from_username_head_img: string;
  to_username_nick_name: string;
  to_username_head_img: string;
  msg_type: MsgType;
  content: string;
  transfer_content: string;
  record_time: number;
  is_show_time?: boolean;
}

export enum TaskMsgType {
  msg_send_text,
  msg_send_image,
  msg_send_voice,
  msg_send_video,
  msg_send_link,
  msg_send_mini_app,
  msg_send_mass_helper,
  msg_send_finder_feed,
  msg_send_finder_live,
  msg_send_finder_card,
  msg_send_gif,
  msg_revoke
}

export interface FriendDetail {
  friend_detail: Friends;
  robot_detail: RobotDetail;
  active: string;
  tag_detail: TagDetail[];
  mp_wechat_detail: MpWechatDetail;
}

enum FriendSex {
  unknown,    //未知
  men,        //男
  women      //女
}


export interface Friends {
  id: number;
  nick_name: string;
  avatar: string;
  wechat: string;
  sex: FriendSex;
  city: string;
  county: string;
  province: string;
  add_time: number;
  from: string;
  remark: string;
  wxid: string;
  unread: number;
  desc: string;
  mobile: string;
}

export interface RobotDetail {
  id: number;
  wxid: number;
  name: string;
  remark: string;
  wechat: string;
  avatar: string;
}

export interface TagDetail{
  id: number;
  name: string;
}

export interface MpWechatDetail{
  wechat_id: number;
  user_id: number;
  order_number: number;
  total_fee: number;
  phone: string;
  vip_no: string;
  birth: string;
  transfer_fee: number;
  money: number;
  join_time: number;
}

export enum TimelineSnsType {
  timeline_sns_type_image = 0, // 图片
  timeline_sns_type_words = 1, // 纯文字
  timeline_sns_type_link = 2,  // 链接
  timeline_sns_type_music = 3, // 音乐
  timeline_sns_type_video = 4, // 视频
  timeline_sns_type_unknown = 100 // 未知
}

export interface TimelineObj {
  sns_id: number;
  username: string; // 好友username
  nickname: string;
  icon: string;
  create_time: number;
  sns_type: TimelineSnsType;
  content: string;
  images: any;
  media: any;
  like_and_comment: string;
  like?: any; // 点赞
  comment?: any; // 评论
  pics?: string[]; // 图片列表
  wechat_id?: number;
}