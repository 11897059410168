










































import {
  Component,
  Vue,
  Watch
} from "vue-property-decorator"
import {
  api
} from '@/api'
import {
  WechatModule
} from "@/store/modules/wechat"
import {
  onScroll
} from "@/utils/func"
import {
  ChatModule
} from "@/store/modules/chat"
import {
  MsgType,
  TaskMsgType
} from "@/utils/interface"

import GlobalFooter from '@/components/global/footer.vue'
import Search from '@/components/global/search.vue'
import GlobalTab from '@/components/global/tab.vue'

@Component({
  name: 'select-friend-page',
  components: {
    GlobalFooter,
    Search,
    GlobalTab
  }
})
export default class SelectFriendPage extends Vue {
  private hasNextPage = true
  private pageNo = 1
  private pageSize = 15
  private list: any = []
  private defChatAvatar: string = 'https://oss.guojiangmedia.com/wxbackend/default-avatar.png'
  private defChatRoomAvatar: string = 'https://oss.guojiangmedia.com/planr/chat-room-icon2.png'
  private sendIndex: number = 0
  private loadingVal: any = null
  private isRequest = false
  private tabVal: any = 0
  private tabList: any = [{
    label: '好友',
    value: 0
  }, {
    label: '群聊',
    value: 1
  }]
  private selectWxStrId: string[] = []
  private selectWxId: number[] = []
  private allChatroomList: any[] = []

  private get deviceId(): number {
    return WechatModule.deviceId
  }

  private get selectChat(): any {
    return ChatModule.selectChatList
  }

  private get wxId(): number {
    return WechatModule.wxId
  }

  private mounted() {
    this.getList()
    this.isRequest = false
  }

  private toBack() {
    this.$router.go(-1)
  }

  private toBackTwo() {
    this.$router.go(-2)
  }

  /**
   * @func 监听滚动
   */
  private listenScroll(e: any) {
    onScroll({
      e: e,
      scrollUp: () => {
        if (this.list.length > 0) {
          this.getList()
        }
      }
    })
  }

  /**
   * @func 获取群聊列表
   */
  private async getChatRoomList() {
    try {
      const res: any = await api.getChatRoom({
        wechat_id: this.wxId
      })
      this.list = res.list
      this.allChatroomList = res.list
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @func 修改tab
   */
  private changeTab(val: number) {
    this.tabVal = val
    if (val) {
      this.list = []
      this.getChatRoomList()
    } else {
      this.pageNo = 1
      this.hasNextPage = true
      this.isRequest = false
      this.list = []
      this.getList()
    }
  }

  /**
   * @func 搜索
   */
  private searchResult(keyword ? : string) {
    if (!this.tabVal) {
      this.pageNo = 1
      this.hasNextPage = true
      this.isRequest = false
      this.list = []
      this.getList(keyword)
    } else if (this.tabVal) {
      this.searchChatroom(keyword)
    }
  }

  /**
   * @func 搜索群聊
   * @param keyword 搜索关键字
   */
  private searchChatroom(keyword ? : string) {
    if (!keyword && keyword != '0') {
      this.getChatRoomList()
    }
    let result: any = []
    for (let i = 0; i < this.allChatroomList.length; i++) {
      if (this.allChatroomList[i].chatroom_nick.indexOf(keyword) !== -1) {
        result.push(this.allChatroomList[i])
      }
    }
    this.list = result
  }

  /**
   * @func 获取好友列表
   */
  private async getList(keyword ? : string) {
    if (this.isRequest) return
    if (!this.hasNextPage) return
    this.isRequest = true
    try {
      const param: any = {
        robot_id: this.deviceId,
        offset: this.pageNo,
        num: this.pageSize,
        keyword: keyword || ''
      }
      const res: any = await api.getFriendsList(param);
      !res.friends || res.friends.length < this.pageSize ?
        this.hasNextPage = false :
        this.pageNo++
      if (res.friends && res.friends.length) {
        res.friends.map((val: any) => {
          this.list.push(val)
        })
      }
      this.isRequest = false
    } catch (err) {
      this.isRequest = false
      console.log(err)
    }
  }

  /**
   * @func 点击好友
   */
  private chooseFri(item: any, type: string) {
    if (type === 'friend') {
      let index = this.selectWxStrId.indexOf(item.friend_detail.wxid)
      if (index >= 0) {
        this.selectWxStrId.splice(index, 1)
        this.selectWxId.splice(index, 1)
      } else {
        this.selectWxStrId.push(item.friend_detail.wxid)
        this.selectWxId.push(item.friend_detail.id)
      }
    } else if (type === 'chatroom') {
      let index = this.selectWxStrId.indexOf(item.username)
      if (index >= 0) {
        this.selectWxStrId.splice(index, 1)
        this.selectWxId.splice(index, 1)
      } else {
        this.selectWxStrId.push(item.username)
        this.selectWxId.push(item.id)
      }
    }
  }

  /**
   * @func 循环转发消息
   */
  private sendMsgLoop(item: any) {
    if (this.selectWxStrId.length === 1) {
      this.loadingVal = this.$weui.loading('发送中...');
      this.sendIndex = 0
      let arr: any = []
      for (let key in this.selectChat) {
        arr.push(this.selectChat[key])
      }
      arr.sort((a: any,b: any) => {
        return a.record_time - b.record_time
      })
      let chatidx = 0
      let timeout = setInterval(() => {
        if (chatidx === arr.length) {
          clearInterval(timeout)
        } else {
          this.sendMsg(this.assembleMsgData(arr[chatidx]), this.selectWxId[0])
        }
        ++chatidx
      }, 1500)
    } else if (this.selectWxStrId.length > 1) {
      this.loadingVal = this.$weui.loading('发送中...');
      let msgList: any = []
      for (let key in this.selectChat) {
        let msg: any = this.selectChat[key]
        msgList.push(this.assembleMsgData(msg).task_single_msg)
      }
      this.massTask(this.selectWxStrId, msgList)
    } else {
      this.$weui.topTips('请选择要发送的好友或群')
      return
    }
  }

  /**
   * @func 组装发送消息
   */
  private assembleMsgData(msg: any) {
    const param: any = {
      task_time: 0,
      task_single_msg: {}
    }

    switch (msg.msg_type) {
      case MsgType.msg_type_text:
        param.task_single_msg = {
          task_type: TaskMsgType.msg_send_text,
          content: msg.content
        }
        break;
      case MsgType.msg_type_img:
        param.task_single_msg = {
          task_type: TaskMsgType.msg_send_image,
          content: msg.content
        }
        break;
      case MsgType.msg_type_video:
        param.task_single_msg = {
          task_type: TaskMsgType.msg_send_video,
          content: msg.content
        }
        break;
      case MsgType.msg_type_emoji:
        param.task_single_msg = {
          task_type: TaskMsgType.msg_send_image,
          content: msg.content
        }
        break;
      case MsgType.msg_type_gif:
        param.task_single_msg = {
          task_type: TaskMsgType.msg_send_gif,
          content: msg.content
        }
        break;
      case MsgType.msg_type_link:
        let content = JSON.parse(msg.content).appmsg;
        param.task_single_msg = {
          task_type: TaskMsgType.msg_send_link,
          title: content.title,
          desc: content.des,
          url: content.url,
          pic: 'https://oss.guojiangmedia.com/sales-order-backend%2Fdefault_link.png'
        }
        break;
    }
    return param
  }

  /**
   * @func 发送消息请求 (当只选中一个好友)
   * @param data 请求数据
   */
  private async sendMsg(data: any, friend_id: number) {
    try {
      const res: any = await api.sendSingleMessage({
        wechat_id: this.wxId,
        task: data,
        friend_id: friend_id,
        friend_type: this.selectWxStrId[0].indexOf('chatroom') !== -1 ? 1 : 0
      })
      this.sendIndex++
      console.log(this.sendIndex, Object.keys(this.selectChat).length)
      if (this.sendIndex === Object.keys(this.selectChat).length) {
        this.loadingVal.hide()
        this.loadingVal = null
        this.toBackTwo()
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @func 群发消息 （当选中一个以上的好友）
   */
  private async massTask(wxids: string[], msgs: any) {
    try {
      await api.massTask({
        wechat_id: this.wxId,
        task: {
          wechat_id: this.wxId,
          task_time: 0,
          remark: '转发任务' + new Date(),
          wxids: wxids,
          mass_interval: 3,
          msgs: msgs
        }
      })
      this.$weui.topTips('创建成功', {
        className: 'success-tips'
      })
      this.loadingVal.hide()
      this.loadingVal = null
      this.toBackTwo()
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @func 搜索
   */
  private toSearch() {
    this.$router.push({
      name: 'Search'
    })
  }

  /**
   * @func 跳转到群聊
   */
  private toChatroom() {
    this.$router.push({
      name: 'Chatroom'
    })
  }

  /**
   * @func 跳转到新好友列表
   */
  private toNewFriend() {
    ChatModule.changeNewFriendNum(0)
    this.$router.push({
      name: 'NewFriend'
    })
  }
}
