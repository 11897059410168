import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';

export interface ChatStore {
  voiceChangeIds: number[];
  audioPlayId: number;
  sendMsgTaskId: number[]; //发送消息返回的taskId
  newFriendNum: number; // 新好友推送数量
  selectChatList: any; // 转发消息列表
}

@Module({dynamic: true, store, name: 'chat'})
class Chat extends VuexModule implements ChatStore {
  @Action({ commit: 'CHANGE_VOICE_IDS' })
  public changeVoiceIds(voiceChangeIds: number[]) {
    return voiceChangeIds;
  }

  @Mutation
  private CHANGE_VOICE_IDS(voiceChangeIds: number[]) {
    this.voiceChangeIds = voiceChangeIds;
  }

  @Action({ commit: 'CHANGE_AUDIO_PLAY_ID' })
  public changeAudioPlayId(audioPlayId: number) {
    return audioPlayId;
  }

  @Mutation
  private CHANGE_AUDIO_PLAY_ID(audioPlayId: number) {
    this.audioPlayId = audioPlayId;
  }

  @Action({ commit: 'CHANGE_SEND_MSG_TASK_ID'})
  public changeMsgTaskId(sendMsgTaskId: number[]) {
    return sendMsgTaskId;
  }

  @Mutation
  private CHANGE_SEND_MSG_TASK_ID(sendMsgTaskId: number[]) {
    this.sendMsgTaskId = sendMsgTaskId;
  }

  @Action({ commit: 'CHANGE_NEW_FRIEND_NUM'})
  public changeNewFriendNum(newFriendNum: number) {
    return newFriendNum
  }

  @Mutation
  private CHANGE_NEW_FRIEND_NUM(newFriendNum: number) {
    this.newFriendNum = newFriendNum;
  }

  @Action({ commit: 'CHANGE_SELECT_CHAT_LIST'})
  public changeSelectChatList(selectChatList: any) {
    return selectChatList
  }

  @Mutation
  private CHANGE_SELECT_CHAT_LIST(selectChatList: any) {
    this.selectChatList = selectChatList;
  }

  public voiceChangeIds: number[] = []
  public audioPlayId: number = 0
  public sendMsgTaskId: number[] = []
  public newFriendNum: number = 0
  public selectChatList: any= {}
}

export const ChatModule = getModule(Chat);