








import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    name: 'global-tab',
})
export default class GlobalTab extends Vue {
  @Prop({}) private list!: any
  private active = 0

  private handleClick(index: number) {
    if (this.active === index) return
    this.active = index
    this.$emit('change', this.list[index].value)
  }
}
